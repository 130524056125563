.main {
	height: 100%;
	margin: 1em;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.option {
	padding: 0.5em;
}

.option button {
	width: 6em;
	margin: 0.5em;
	padding: 0.5em;
	margin-left: 1em;
}

.option button.liquid {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	border-radius: 0.25em;
	color: #faf7f7;
	background: linear-gradient(to bottom,#2302e0,#4b56f0);
    border-color: #a88734 #9c7e31 #846a29;
    cursor: pointer;
    box-shadow: 0 1px 0 rgb(255 255 255 / 40%) inset;
    border-style: solid;
    border-width: 1px;
}

.option button.trade {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	border-radius: 0.25em;
	color: #111;
    border-color: #a88734 #9c7e31 #846a29;
    cursor: pointer;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset;
    border-style: solid;
    border-width: 1px;
}

.option button:hover {
	cursor: pointer;
}

