.navbar {
	background-color: #2951c0;
	color: #fff;
	display: flex;
	padding: 0.5em 0.5em;
	justify-content: center;
}

.navbar div {
	margin: 0 .2em;
}

.navbar div:nth-child(1) {
	font-weight: 500;
	font-size: 1.2em;
}

.navbar div:nth-child(2) {
	margin-left: auto;
}

.navbar:hover {
	background-color: #20c997;
}

@media screen and (max-width: 616px) {
	.navbar {
		justify-content: space-between;
	}

	.navbar div:nth-child(2) {
		display: none;
	}
}
.liquidity {
	display: flex;
	height: 100%;
}

.liquidity {
	padding: 1em 0.5em;
}

.liquidity .pool__summary {
	width: 40%;
	height: 100%; 
}

.liquidity .pool__data {
	border: 1px solid #ced4da;
	margin: 0 0.8em 0.8em 0.8em;
	border-radius: 0.25em;
	padding: 0.5em;
}

.liquidity .pool__pair {
	border: 1px solid #ced4da;
	display: flex;
	align-items: center;
	margin: 0.7em 0 0.7em 0;
	padding: 0.5em;
	border-radius: 0.25em;
	max-width: 100%;
}

.pool__pair img {
	margin-right: 0.2em;
}

.liquidity .pool__data p, div {
	margin-top: 0;
	margin-bottom: 0;
}

.pool__pair .token__value {
	margin-left: auto;
}


.liquidity .pool__liquid {
	width: 60%;
	height: 100%;
	border-radius: 0.25em;
	margin-left: 1em;
}

.liquidity__form {
	border: 1px solid #ced4da;
	padding: 1.5em;
	border-radius: 0.25em;
}

.liquid__inputs {
	margin: 0.5em 0 0.5em 0;
}

.liquidity__form div {
	display: flex;
}

.liquidity__form input {
	width: 80%;
	border: 1px solid #ced4da;
	padding: 1em;
	border-radius: 0.25em;
	font-size: 0.9em;
}

.liquidity__form .pairs {
	border: 1px solid #ced4da;
	width: 6em;
	align-items: center;
	justify-content: left;
	border-radius: 0.25em;
}

.pairs select {
	border: transparent;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.liquidity__form button {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	width: 100%;
	border-radius: 0.25em;
	padding: 1em;
	color: #111;
	background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    border-color: #a88734 #9c7e31 #846a29;
    cursor: pointer;
    box-shadow: 0 1px 0 rgb(255 255 255 / 40%) inset;
    border-style: solid;
    border-width: 1px;
}

@media screen and (max-width: 799px) {
	.liquidity {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
	}

	.liquidity .pool__liquid  {
		/*margin-top: 5em;*/
		width: 100%;
	}

	.liquidity .pool__summary {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.liquidity .pool__summary .pool__data {
		max-width: 100%;
	}
}

@media screen and (max-width: 643px) {

	.liquidity .pool__summary {
		flex-direction: column;
	}

	.liquidity .pool__liquid  {
		/*margin-top: 5em;*/
		width: 100%;
	}
}


.liquidity__form .tokens {
	justify-content: center;
}

.liquidity__form .tokens div {
	border: 1px solid #dad5ce;
	margin: 0 0.2em;
	border-radius: 0.25em;
	padding: 0.1em;
}

.liquidity__form .tokens div.active {
	background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    border-color: #a88734 #9c7e31 #846a29;
    box-shadow: 0 1px 0 rgb(255 255 255 / 40%) inset;
    border-style: solid;
    border-width: 1px;
}

.liquidity__form .tokens div:hover {
	cursor: pointer;
}

.liquidity__form .tokens select {
	margin-left: auto;
	margin-right: auto;
}
.liquidity__form button.trader {
	background: linear-gradient(to bottom,rgb(245, 184, 15),rgb(245, 184, 15));
}

.main {
	height: 100%;
	margin: 1em;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.option {
	padding: 0.5em;
}

.option button {
	width: 6em;
	margin: 0.5em;
	padding: 0.5em;
	margin-left: 1em;
}

.option button.liquid {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	border-radius: 0.25em;
	color: #faf7f7;
	background: linear-gradient(to bottom,#2302e0,#4b56f0);
    border-color: #a88734 #9c7e31 #846a29;
    cursor: pointer;
    box-shadow: 0 1px 0 rgb(255 255 255 / 40%) inset;
    border-style: solid;
    border-width: 1px;
}

.option button.trade {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	border-radius: 0.25em;
	color: #111;
    border-color: #a88734 #9c7e31 #846a29;
    cursor: pointer;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset;
    border-style: solid;
    border-width: 1px;
}

.option button:hover {
	cursor: pointer;
}


/* Styles go here */
html, body {
	padding: 0;
	margin: 0;
	height: 100%;
}

#root, .app {
	height: 100%;
}

body {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}


