/* Styles go here */
html, body {
	padding: 0;
	margin: 0;
	height: 100%;
}

#root, .app {
	height: 100%;
}

body {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

