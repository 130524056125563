.liquidity {
	display: flex;
	height: 100%;
}

.liquidity {
	padding: 1em 0.5em;
}

.liquidity .pool__summary {
	width: 40%;
	height: 100%; 
}

.liquidity .pool__data {
	border: 1px solid #ced4da;
	margin: 0 0.8em 0.8em 0.8em;
	border-radius: 0.25em;
	padding: 0.5em;
}

.liquidity .pool__pair {
	border: 1px solid #ced4da;
	display: flex;
	align-items: center;
	margin: 0.7em 0 0.7em 0;
	padding: 0.5em;
	border-radius: 0.25em;
	max-width: 100%;
}

.pool__pair img {
	margin-right: 0.2em;
}

.liquidity .pool__data p, div {
	margin-top: 0;
	margin-bottom: 0;
}

.pool__pair .token__value {
	margin-left: auto;
}


.liquidity .pool__liquid {
	width: 60%;
	height: 100%;
	border-radius: 0.25em;
	margin-left: 1em;
}

.liquidity__form {
	border: 1px solid #ced4da;
	padding: 1.5em;
	border-radius: 0.25em;
}

.liquid__inputs {
	margin: 0.5em 0 0.5em 0;
}

.liquidity__form div {
	display: flex;
}

.liquidity__form input {
	width: 80%;
	border: 1px solid #ced4da;
	padding: 1em;
	border-radius: 0.25em;
	font-size: 0.9em;
}

.liquidity__form .pairs {
	border: 1px solid #ced4da;
	width: 6em;
	align-items: center;
	justify-content: left;
	border-radius: 0.25em;
}

.pairs select {
	border: transparent;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.liquidity__form button {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	width: 100%;
	border-radius: 0.25em;
	padding: 1em;
	color: #111;
	background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    border-color: #a88734 #9c7e31 #846a29;
    cursor: pointer;
    box-shadow: 0 1px 0 rgb(255 255 255 / 40%) inset;
    border-style: solid;
    border-width: 1px;
}

@media screen and (max-width: 799px) {
	.liquidity {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
	}

	.liquidity .pool__liquid  {
		/*margin-top: 5em;*/
		width: 100%;
	}

	.liquidity .pool__summary {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.liquidity .pool__summary .pool__data {
		max-width: 100%;
	}
}

@media screen and (max-width: 643px) {

	.liquidity .pool__summary {
		flex-direction: column;
	}

	.liquidity .pool__liquid  {
		/*margin-top: 5em;*/
		width: 100%;
	}
}

