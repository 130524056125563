.navbar {
	background-color: #2951c0;
	color: #fff;
	display: flex;
	padding: 0.5em 0.5em;
	justify-content: center;
}

.navbar div {
	margin: 0 .2em;
}

.navbar div:nth-child(1) {
	font-weight: 500;
	font-size: 1.2em;
}

.navbar div:nth-child(2) {
	margin-left: auto;
}

.navbar:hover {
	background-color: #20c997;
}

@media screen and (max-width: 616px) {
	.navbar {
		justify-content: space-between;
	}

	.navbar div:nth-child(2) {
		display: none;
	}
}